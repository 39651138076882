<template>
    <n-card :bordered="false">
        <div style="text-align: center;">
            <h2> Hospitality Guaranteed are now: </h2>
            <a href="https://www.servace.co.uk/">www.servace.co.uk</a>
            <br />
            <span class="vertical-spacing">T: 0344 8223227</span>
            <div class="vertical-spacing">
                <img src="../images/servace-logo.png" style="width: 200px;" />
            </div>
        </div>
    </n-card>
</template>

<script>
</script>

<style>
.vertical-spacing {
    position: relative;
    top: 1rem;
}
</style>